const base = {
    APIlogin: '/login', //登录
    APIeasySms: '/easySms', //验证码
    APIgetwxinfo: '/getwxinfophone', //微信登陆
    APIresgiter: '/resgiter', //注册
    APIclassesindexlist: '/question/classesindexlist', //首页jinqu
    APIme: '/me', //个人中心
    APIforgetpwd: '/forgetpwd', //找回密码

    APIclassQuestion: '/question/class', //首页分类
    APIrecords: '/question/class/records', //创建试卷
    APIclasses: '/question/classes', //首页

    APIshows: '/question/class/records/shows', //开始答题
    APIrecordtiemslist: '/question/recordtiemslist', //查看成绩
    APIsubmittest: "/question/class/records", //提交试卷
    APIwxLoginphone: "/wxLoginphone", //扫码登陆
    APIindex: "/index", //做题（错题）数量，时长
    APIcollotion: "/question/records/collection", //收藏题
    APIshowanalysis: "/question/class/records/count/", //查看解析-试题数量
    APIIndexList: "/question/classesindexlist", //首页列表
    APIcheck: "/check", //检查是否是新用户
    APIavatarInfo: "/avatar/info", //获取上传授权信息（阿里云）【1】【头像】
    APIspanishknow: "http://img.spanishknow.com", //获取上传授权信息（阿里云）【2】【头像】
    APIEditme: "/me", //修改个人信息
    APIquestionclassshow: "/question/class/show", //试卷详情
    APIdiscuss: "/question/comment", //获取评论
    APIdiscussCreate: "/question/comment", //创建评论
    APIsupport: "/question/comment/support", //创建赞/取消赞
    APIrecordslist: "/question/error/recordslist", //错题集
    APIemptyerrorstate: "/question/records/emptyerrorstate", //清空（删除）错题
    APIcollectlist: "/question/collectlist", //收藏
    APIemptycollection: "/question/records/collection", //清空收藏
    APIspecial: "/question/class/special", //专项练习
    APIsortme: "/question/rank/me", //用户排行(自己的排名，需要登录)
    APIWeeklyranking: "/question/rank", //用户排行
    APIpassworduser: "/inside/questions/classes/passworduser", //系统后台，查看试卷加密后做题用户
    APIpasswordusercheck: "/question/class/passworduser/check", //用户，查询加密试卷用户是否解密过
    APIpasswordcheck:"/question/class/password/check",//检查用户的密码是否正确

}
export default base;