import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    question: {},
    rowObj: {},
    windurl:""
  },
  mutations: {
    changeCreated(state, question) {
      state.question = question
    },
    changeRowObj(state, rowObj) {
      state.rowObj = rowObj
    },
    changeurl(state, windurl) {
      state.windurl = windurl
    },

  },
  actions: {},
  modules: {}
})