import base from './base';
import axios from '../utils/http';
const api = {
    login: (params) => axios.post(base.APIlogin, params),
    getToken: (params) => axios.put(base.APIlogin, ),
    easySms: (params) => axios.post(base.APIeasySms, params),
    resgiter: (params) => axios.post(base.APIresgiter, params),
    getwxinfo: (params) => axios.get(base.APIgetwxinfo + `/${params.token}`, ),
    me: (params) => axios.get(base.APIme, ),

    classQuestion: (params) => axios.get(base.APIclassQuestion + `/${params.id}`, ),

    classesindexlist: (params) => axios.get(base.APIclassesindexlist, {
        params: params
    }),

    classes: (params) => axios.get(base.APIclasses, {
        params: params
    }),

    records: (params) => axios.post(base.APIrecords, params),
    shows: (params) => axios.get(base.APIshows + `/${params.id}`, ),
    recordtiemslist: (params) => axios.get(base.APIrecordtiemslist + `/${params.id}`, ),
    submittest: (params) => axios.put(base.APIsubmittest, params.param),
    wxLoginphone: (params) => axios.post(base.APIwxLoginphone, params),
    index: (params) => axios.get(base.APIindex, {
        params: params
    }),
    collotion: (params) => axios.put(base.APIcollotion, params),
    analysis: (params) => axios.get(base.APIshowanalysis + `${params.id}`, ),
    forgetpwd: (params) => axios.post(base.APIforgetpwd, params),
    indexlist: (params) => axios.get(base.APIIndexList, {
        params: params
    }),

    check: (params) => axios.get(base.APIcheck, ),
    avatarInfo: (params) => axios.get(base.APIavatarInfo, {
        params: params
    }),
    editme: (params) => axios.post(base.APIEditme, params),
    questionclassshow: (params) => axios.get(base.APIquestionclassshow + `/${params.id}`, ),

    discuss: (params) => axios.get(base.APIdiscuss, {
        params: params
    }),
    discussCreate: (params) => axios.post(base.APIdiscussCreate, params),
    support: (params) => axios.post(base.APIsupport, params),

    errorlist: (params) => axios.get(base.APIrecordslist, {
        params: params
    }),
    emptyerrorstate: (params) => axios.put(base.APIemptyerrorstate, params),
    collectionlist: (params) => axios.get(base.APIcollectlist, {
        params: params
    }),
    emptycollection: (params) => axios.put(base.APIemptycollection, params),
    special: (params) => axios.get(base.APIspecial + `/${params.id}`, {
        params: params.msg
    }),
    sortme: (params) => axios.get(base.APIsortme, {
        params: params
    }),
    Weeklyranking: (params) => axios.get(base.APIWeeklyranking, {
        params: params
    }),
    passworduser: (params) => axios.get(base.APIpassworduser, {
        params: params
    }), //系统后台，查看试卷加密后做题用户
    passwordusercheck: (params) => axios.get(base.APIpasswordcheck, {
        params: params
    }), //用户，查询加密试卷用户是否解密过

    APIpasswordusercheck: (params) => axios.get(base.APIpasswordusercheck, {
        params: params
    }), //用户，查询加密试卷用户是否解密过

    

}




export default api;