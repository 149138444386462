import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import api from './api/api'
import './assets/css/reset.css';
import './assets/icon/iconfont.css'
import Vant from 'vant';
import 'vant/lib/index.css';
// import VLayer from 'vlayer';
Vue.use(ElementUI)
// Vue.use(VLayer);
Vue.config.productionTip = false
Vue.use(Vant);
import VueMeta from 'vue-meta';
Vue.use(VueMeta, {
  // 定义组件中的数据对象
  keyName: 'head',
});
// 挂原型
Vue.prototype.$api = api
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')