import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '../views/Login.vue'
import api from "@/api/api"
Vue.use(VueRouter)

const routes = [
  //   {
  //   path: '/Login',
  //   name: 'Login',
  //   component: Login
  // }
  // ,
  {
    path: '/wxLogin',
    name: 'wxLogin',
    component: () => import('../views/wxLogin.vue')
  },
  // , {
  //     path: '/Regist',
  //     name: 'Regist',
  //     component: () => import('../views/Regist.vue')
  //   }, {
  //     path: '/Forget',
  //     name: 'Forget',
  //     component: () => import('../views/Forget.vue')
  //   },
  {
    path: '/',
    name: 'Index',
    redirect: "/Index/Gateway",
    component: () => import('../views/Index.vue'),
    children: [{
        path: '/Index/Home',
        name: 'Home',
        component: () => import('../views/Index/Home.vue'),
      }, {
        path: '/Index/TestList',
        name: 'TestList',
        component: () => import('../views/Index/TestList.vue'),
      }, {
        path: '/Index/Notice',
        name: 'Notice',
        component: () => import('../views/Index/Notice.vue'),
      }, {
        path: '/Index/Exam',
        name: 'Exam',
        component: () => import('../views/Index/Exam.vue'),
      },
      {
        path: '/Index/Pratice',
        name: 'Pratice',
        component: () => import('../views/Index/Pratice.vue'),
      }, {
        path: '/Index/Answersheet',
        name: 'Answersheet',
        component: () => import('../views/Index/Answersheet.vue'),
      }, {
        path: '/Index/Gateway',
        // 
        name: 'Gateway',
        component: () => import("../views/Index/Gateway.vue")
      }, {
        path: '/Index/Analysis',
        // 
        name: 'Analysis',
        component: () => import("../views/Index/Analysis.vue")
      }, {
        path: '/Index/Testinfo',
        // 
        name: 'Testinfo',
        component: () => import("../views/Index/Testinfo.vue")
      },
      // 错题集
      {
        path: '/Index/PersonalCenter/WrongQuestions',
        name: 'WrongQuestions',
        component: () => import("../views/Index/PersonalCenter/WrongQuestions.vue")
      },
      // 收藏
      {
        path: '/Index/PersonalCenter/colltion',
        name: 'colltion',
        component: () => import("../views/Index/PersonalCenter/colltion.vue")
      },
      // 专项练习
      {
        path: '/Index/PersonalCenter/Specialpartice',
        name: 'Specialpartice',
        component: () => import("../views/Index/PersonalCenter/Specialpartice.vue")
      },
      // 专项练习-题型
      {
        path: '/Index/PersonalCenter/QuestionType',
        name: 'QuestionType',
        component: () => import("../views/Index/PersonalCenter/QuestionType.vue")
      },
      // 排行榜
      {
        path: '/Index/PersonalCenter/RankingList',
        name: 'RankingList',
        component: () => import("../views/Index/PersonalCenter/RankingList.vue")
      },
      
    ]
  }
]

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes
})

// router.beforeEach((to, from, next) => {
//   if (localStorage.userInfo) {
//     api.getToken().then((res) => {
//       console.log(res.data);
//       localStorage.userInfo = JSON.stringify(res.data);
//       next()
//     });
//   } else {
//     next()
//   }

// })
export default router